import * as authActions from './authActions';
import * as vehicleActions from './vehicleActions';
import * as driverActions from './driverActions';
import * as orderActions from './orderActions';
import * as initDataAction from './initDataAction';
import * as confirmationAction from './confirmationAction';

export default {
    ...authActions,
    ...vehicleActions,
    ...driverActions,
    ...orderActions,
    ...initDataAction,
    ...confirmationAction
};
