import {apiCall} from '../../api/apiCall';
import {apiUrl} from '../../config';
import {FETCH_ERROR, IS_AUTH} from '../actionTypes';
import {saveToken} from '../../api/saveToken';
import {removeToken} from '../../api/removeToken';

export const loginAction = action => async dispatch => {
    try {
        const res = await apiCall().post(`${apiUrl}/identity/login/web`, action);
        saveToken(res.data);
        dispatch({type: IS_AUTH, payload: true});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const logoutAction = action => async dispatch => {
    removeToken();
    dispatch({type: IS_AUTH, payload: false});
};

export const registerCustomer = action => async dispatch => {
    try {
        const res = await apiCall().post(`${apiUrl}/identity/register/customer`, action);
        saveToken(res.data);
        dispatch({type: IS_AUTH, payload: true});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};
export const registerCompany = action => async dispatch => {
    try {
        const res = await apiCall().post(`${apiUrl}/identity/register/company`, action);
        saveToken(res.data);
        dispatch({type: IS_AUTH, payload: true});

    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};
export const registerDriver = action => async dispatch => {
    try {
        const res = await apiCall().post(`${apiUrl}/identity/register/driver`, action);
        saveToken(res.data);
        dispatch({type: IS_AUTH, payload: true});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

