import React from 'react';
import {Route} from 'react-router-dom';


const PublicRoute = (
    {
        component: Component,
        isAuthenticated,
        ...routeProps
    }
) => {

    return (
        <Route {...routeProps}>
            <Component {...routeProps} />
        </Route>
    );
};

export default PublicRoute;
