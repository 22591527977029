import React, {useEffect, useState} from 'react';
import InputConfirm from './components/InputConfirm';
import './style.less';
import {useAction} from '../hooks/useAction';

const Confirmation = () => {
    const {confirmEmail, confirmPhone} = useAction();
    const [isConfirmEmail, setEmail] = useState('');
    const [isConfirmPhone, setPhone] = useState('');

    const handleConfirmation = async () => {
        const payloadPhone = {
            code: isConfirmPhone
        };
        confirmPhone({payloadPhone});
        const payloadEmail = {
            code: isConfirmEmail
        };
        confirmEmail({payloadEmail});
    };


    return (
        <div className="confirmation">
            <div className="confirmation-form">
                <InputConfirm
                    label="Please check your e-mail"
                    value={isConfirmEmail}
                    placeholder="Enter your code"
                    handler={setEmail}
                />
                <InputConfirm
                    label="Please check your phone"
                    value={isConfirmPhone}
                    placeholder="Enter your code"
                    handler={setPhone}
                />
                <div className="confirmation-button">
                    <button
                        className="confirmation-button__btn"
                        disabled={isConfirmEmail === '' || isConfirmPhone === ''}
                        onClick={() => handleConfirmation()}
                    >Confirmation
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Confirmation;
