import {apiCall} from '../../api/apiCall';
import {
    ADD_VEHICLE,
    ALL_VEHICLES,
    FETCH_ERROR,
    FETCH_READY,
    FETCH_SUCCESS,
    UPDATE_VEHICLE
} from '../actionTypes';
import {initVehicle} from '../reducers/vehicles';

export const getAllVehicles = action => async (dispatch) => {

    try {
        const res = await apiCall().get(`/vehicle/carrier/getAll`);
        dispatch({type: ALL_VEHICLES, payload: res.data});
        dispatch({type: FETCH_READY, payload: 'ready'});
    } catch (e) {
        console.log('@@@ vehicle add action', e.message);
    }
};

export const vehicleGetById = action => async dispatch => {
    try {
        const res = await apiCall().get(`/vehicle/get?vehicleId=${action.id}`);
        await dispatch({type: UPDATE_VEHICLE, payload: res.data});
        dispatch({type: FETCH_READY, payload: 'ready'});
    } catch (e) {
        console.log('@@@ vehicle get by id action', e.message);
        dispatch({type: FETCH_ERROR, payload: 'error'});
    }
};

export const vehicleAdd = action => async dispatch => {
    try {
        await apiCall().post('/vehicle/add', action.payload);
        dispatch({type: FETCH_SUCCESS, payload: 'success'});

    } catch (e) {
        console.log('@@@ vehicle add action', e.message);
        dispatch({type: FETCH_ERROR, payload: 'error'});
    }
};

export const vehicleEdit = action => async dispatch => {
    try {
        await apiCall().put('/vehicle/edit', action.payload);
        const res = await apiCall().get(`/vehicle/get?vehicleId=${action.payload.vehicleId}`);

        dispatch({type: UPDATE_VEHICLE, payload: res.data});
        dispatch({type: FETCH_SUCCESS, payload: 'success'});
    } catch (e) {
        console.log('@@@ vehicle add action', e.message);
        dispatch({type: FETCH_ERROR, payload: 'error'});
    }
};

export const vehicleChangeStatus = action => async dispatch => {

    try {
        await apiCall().put('/vehicle/changeStatus', action.payload);
        dispatch({type: FETCH_SUCCESS, payload: 'success'});

        const res = await apiCall().get(`/vehicle/carrier/getAll`);
        dispatch({type: ALL_VEHICLES, payload: res.data});
    } catch (e) {
        console.log('@@@ vehicle change status action', e.message);
        dispatch({type: FETCH_ERROR, payload: 'error'});
    }
};

export const vehicleAssignedDriver = action => async dispatch => {
    try {
        await apiCall().put('/vehicle/assignDrivers', action.payload);

        const res = await apiCall().get(`/vehicle/carrier/getAll`);
        dispatch({type: ALL_VEHICLES, payload: res.data});
        dispatch({type: FETCH_READY, payload: 'ready'});
    } catch (e) {
        console.log('@@@ vehicle change status action', e.message);
        dispatch({type: FETCH_ERROR, payload: 'error'});
    }
};

export const vehicleDelete = action => async dispatch => {
    try {
        await apiCall().delete(`/vehicle/delete?vehicleId=${action.id}`);
        dispatch({type: FETCH_SUCCESS, payload: 'success'});

        const res = await apiCall().get(`/vehicle/carrier/getAll`);
        dispatch({type: ALL_VEHICLES, payload: res.data});
    } catch (e) {
        console.log('@@@ vehicle change status action', e.message);
        dispatch({type: FETCH_ERROR, payload: 'error'});
    }
};

export const vehicleResetFields = (action) => async dispatch => {
    dispatch({type: ADD_VEHICLE, payload: initVehicle});
};
