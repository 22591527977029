import {apiCall} from '../../api/apiCall';
import {FETCH_READY, ORDER_GET_ALL} from '../actionTypes';

export const orderGetAll = action => async dispatch => {
    try {
        const res = await apiCall().get(`/order/carrier/getAll?statusType=${action.status}`);
        dispatch({type: ORDER_GET_ALL, payload: res.data});
        dispatch({type: FETCH_READY, payload: 'ready'});
    } catch (e) {
    }
};
