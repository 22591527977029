export const driverMenu = {
    name: 'My drivers',
    paths: [
        {
            label: 'All drivers',
            path: '/drivers/all-drivers'
        },
        {
            label: 'Add driver',
            path: '/drivers/add-driver'
        }
    ]
};
