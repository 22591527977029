import React from 'react';

const InputConfirm = (
    {
        label,
        value,
        placeholder,
        handler
    }
) => {
    return (
        <div className="confirmation-input">
            <label className="confirmation-input__label">{label}</label>
            <input
                className="confirmation-input__input"
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(event) => handler(event.target.value)}
            />
        </div>
    );
};

export default InputConfirm;
