import {ADD_VEHICLE, ALL_VEHICLES, UPDATE_VEHICLE} from '../actionTypes';

const initVehicles = {
    items: []
};

export const allVehicles = (state = initVehicles, action) => {
    switch (action.type) {
        case ALL_VEHICLES:
            return {
                ...state,
                items: action.payload
            };
        default:
            return state;
    }
};

export const initVehicle = {
    id: null,
    cityPrice: 0,
    highwayPrice: 0,
    minimumPrice: 0,
    maxWeight: 0,
    registrationNumber: '',
    vehicleServiceType: 1,
    vehicleType: 1,
    length: 0,
    width: 0,
    rampDegree: 0,
    country: '',
    city: '',
    placeId: '',
    workRadius: '',
    latitude: 0,
    longitude: 0,
    driverIds: [],
    statusType: 1,
    vehicleDrivers: []
};


export const vehicle = (state = initVehicle, action) => {

    switch (action.type) {
        case ADD_VEHICLE:
            return {
                ...state,
                cityPrice: action.payload.cityPrice,
                highwayPrice: action.payload.highwayPrice,
                minimumPrice: action.payload.minimumPrice,
                maxWeight: action.payload.maxWeight,
                registrationNumber: action.payload.registrationNumber,
                length: action.payload.length,
                width: action.payload.width,
                rampDegree: action.payload.rampDegree,
                district: action.payload.district,
                diverId: action.payload.diverId,
                vehicleServiceType: action.payload.vehicleServiceType,
                vehicleType: action.payload.vehicleType,
                country: action.payload.country,
                area: action.payload.area,
                latitude: action.payload.latitude,
                longitude: action.payload.longitude,
            };
        case UPDATE_VEHICLE:
            return {
                ...state,
                id: action.payload.id,
                cityPrice: action.payload.cityPrice,
                highwayPrice: action.payload.highwayPrice,
                minimumPrice: action.payload.minimumPrice,
                maxWeight: action.payload.maxWeight,
                registrationNumber: action.payload.registrationNumber,
                vehicleServiceType: action.payload.vehicleServiceType,
                vehicleType: action.payload.vehicleType,
                length: action.payload?.dimensions?.length,
                width: action.payload?.dimensions?.width,
                rampDegree: action.payload?.dimensions?.rampDegree,
                country: action.payload.coverageArea?.country,
                city: action.payload.coverageArea?.city,
                workRadius: action.payload.coverageArea?.workRadius,
                placeId: action.payload.coverageArea?.placeId,
                latitude: action.payload.coverageArea?.latitude,
                longitude: action.payload.coverageArea?.longitude,
                vehicleDrivers: action.payload.vehicleDrivers,
                statusType: action.payload.statusType
            };
        default:
            return state;
    }
};
