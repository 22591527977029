import {apiCall} from '../../api/apiCall';
import {apiUrl} from '../../config';
import {FETCH_ERROR, IS_EMAIL_CONFIRM, IS_PHONE_CONFIRM} from '../actionTypes';

export const confirmEmail = action => async dispatch => {
    try {
        const res = await apiCall().post(`${apiUrl}/confirmation/confirmEmail`, action.payloadEmail);
        dispatch({type: IS_EMAIL_CONFIRM, payload: res.data.success});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const confirmPhone = action => async dispatch => {
    try {
        const res = await apiCall().post(`${apiUrl}/confirmation/confirmPhone`, action.payloadPhone);
        dispatch({type: IS_PHONE_CONFIRM, payload: res.data.success});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};
