import React, {useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import {useSelector} from 'react-redux';
import {useAction} from '../hooks/useAction';

const PrivateRoute = (
        {
            component: Component,
            ...routeProps
        }
    ) => {
        const {
            isAuth,
            role,
            vehicleServices,
            bulkCargoVehicles,
            trawlingVehicles,
        } = useSelector((state => state.app));

        const {getAllDrivers, getAllVehicles, getAppDataSelectLists} = useAction();

    useEffect(() => {
        if (isAuth) {
            !role.includes('NaturalPerson') && getAllDrivers();
            getAllVehicles();
            if (!vehicleServices.length, !bulkCargoVehicles.length, !trawlingVehicles.length) {
                getAppDataSelectLists();
            }
        }
    }, []);

        return (
            <Route {...routeProps}>
                {isAuth
                    ? (
                        <MainLayout>
                            <Component {...routeProps}/>
                        </MainLayout>
                    )
                    : <Redirect to={'/'}/>}
            </Route>
        );
    }

;

export default PrivateRoute;
