import {apiCall} from '../../api/apiCall';
import {ALL_DRIVERS, FETCH_ERROR, FETCH_READY, FETCH_SUCCESS, UPDATE_DRIVER} from '../actionTypes';
import {apiUrl} from '../../config';
import {initDriver} from '../reducers/drivers';

export const getAllDrivers = action => async (dispatch) => {
    try {
        const res = await apiCall().get(`/driver/getAll`);
        dispatch({type: ALL_DRIVERS, payload: res.data});
        dispatch({type: FETCH_READY, payload: 'ready'});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const driverGetById = action => async dispatch => {
    try {
        const res = await apiCall().get(`/driver/get?driverId=${action.id}`);

        dispatch({type: UPDATE_DRIVER, payload: res.data});
        dispatch({type: FETCH_READY, payload: 'ready'});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const driverAdd = action => async dispatch => {
    try {
        await apiCall().post('/driver/add', action.payload);
        dispatch({type: FETCH_SUCCESS, payload: 'success'});
    } catch (e) {

        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const driverChangeStatus = action => async dispatch => {
    try {
        await apiCall().put(`${apiUrl}/driver/forceSignOut`, action.payload);
        dispatch({type: FETCH_SUCCESS, payload: 'success'});

        const res = await apiCall().get(`/driver/getAll`);
        dispatch({type: ALL_DRIVERS, payload: res.data});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const driverEdit = action => async dispatch => {
    try {
        await apiCall().put(`${apiUrl}/driver/edit`, action.payload);
        const res = await apiCall().get(`/driver/get?driverId=${action.id}`);

        dispatch({type: UPDATE_DRIVER, payload: res.data});
        dispatch({type: FETCH_SUCCESS, payload: 'success'});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const driverChangePassword = action => async dispatch => {
    try {
        await apiCall().post(`${apiUrl}/driver/setPassword`, action.payload);
        dispatch({type: FETCH_SUCCESS, payload: 'success'});
    } catch (e) {

        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const driverAssignedVehicles = action => async dispatch => {
    try {
        await apiCall().put('/driver/assignVehicles', action.payload);
        const res = await apiCall().get(`/driver/getAll`);

        dispatch({type: ALL_DRIVERS, payload: res.data});
        dispatch({type: FETCH_READY, payload: 'ready'});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const driverDelete = action => async dispatch => {
    try {
        await apiCall().delete(`/driver/delete?driverId=${action.id}`);
        const res = await apiCall().get(`/driver/getAll`);

        dispatch({type: ALL_DRIVERS, payload: res.data});
        dispatch({type: FETCH_SUCCESS, payload: 'success'});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error', messages: e.response.data.errors});
    }
};

export const driverResetFields = action => async dispatch => {
    dispatch({type: UPDATE_DRIVER, payload: initDriver});
};
