import {LOGIN, LOGIN_PHONE} from '../actionTypes';

const loginInitState = {
    email: '',
    phone: '',
    password: ''
};

export const login = (state = loginInitState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password
            };
        case LOGIN_PHONE:
            return {
                ...state,
                phone: action.payload.phone
            }
        default:
            return state;
    }
};
