import {FETCH_ERROR, FETCH_READY, INIT_DATA_APP} from '../actionTypes';
import {apiCall} from '../../api/apiCall';

export const getAppDataSelectLists = action => async dispatch => {
    try {
        const vehicleServices = await apiCall().get('/data/types/vehicleServices');
        const bulkCargoVehicles = await apiCall().get('data/types/bulkCargoVehicles');
        const trawlingVehicles = await apiCall().get('data/types/trawlingVehicles');
        const orderStatuses = await apiCall().get('/data/types/orderStatuses');

        dispatch({
            type: INIT_DATA_APP,
            payload: {
                vehicleServices: vehicleServices.data,
                bulkCargoVehicles: bulkCargoVehicles.data,
                trawlingVehicles: trawlingVehicles.data,
                orderStatuses: orderStatuses.data
            }
        });
        dispatch({type: FETCH_READY, payload: 'ready'});
    } catch (e) {
        dispatch({type: FETCH_ERROR, payload: 'error'});
    }
};
