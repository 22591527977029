import jwt_decode from 'jwt-decode';

export function saveToken(data) {
    const session = jwt_decode(data?.token);
    const auth = {
        user: session?.given_name ?? null,
        email: session.email ?? null,
        exp: session?.exp ?? null,
        token: data?.token,
        refreshToken: data?.refreshToken,
        role: session.role,
        email_verified: session.email_verified === 'True',
        phone_number_verified: session.phone_number_verified === 'True'
    };

    sessionStorage.setItem('auth', JSON.stringify(auth));
}
