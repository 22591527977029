import axios from 'axios';
import {apiUrl} from '../config';
import {saveToken} from './saveToken';

export function refreshToken(data) {
    console.log('refresh', data.refreshToken);
    return axios.post(`${apiUrl}/identity/refresh`, {
        token: data.token,
        refreshToken: data.refreshToken,
        forceRefresh: true
    }).then(res => {
        if (res.status === 200) {
            saveToken(res.data);
            return Promise.resolve();
        }
        return Promise.reject();
    }).catch(error => {
        return Promise.reject();
    });
}
