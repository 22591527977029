import {combineReducers} from 'redux';
import {login} from './authReducer';
import {app} from './app';
import {allVehicles, vehicle} from './vehicles';
import {allDrivers, driver} from './drivers';
import {orders} from './orders'

const reducers = combineReducers({
    app,
    login,
    allVehicles,
    vehicle,
    allDrivers,
    driver,
    orders
});

export default reducers;
