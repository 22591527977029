// =============== auth ===========
export const IS_AUTH = 'IS_AUTH';
export const SET_ROLE = 'SET_ROLE';
export const IS_EMAIL_CONFIRM = 'IS_EMAIL_CONFIRM';
export const IS_PHONE_CONFIRM = 'IS_PHONE_CONFIRM';
export const FETCH_SUCCESS = 'SUCCESS';
export const FETCH_ERROR = 'ERROR';
export const FETCH_READY = 'FETCH_READY';
export const INIT_DATA_APP = 'INIT_DATA_APP';
export const LOGIN = 'LOGIN';
export const LOGIN_PHONE = 'LOGIN_PHONE';
export const REGISTER = 'REGISTER';

// ============ vehicles ==========
export const ALL_VEHICLES = 'ALL_VEHICLES';
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
// ============ drivers ===========
export const ALL_DRIVERS = 'ALL_DRIVERS';
export const ADD_DRIVER = 'ADD_DRIVER';
export const GET_DRIVER = 'GET_DRIVER';
export const UPDATE_DRIVER = 'UPDATE_DRIVER';
// ============ orders ============
export const ORDER_GET_ALL = 'ORDER_GET_ALL';

