import {lazy} from 'react';

export const publicRoutes = [
    {
        path: '/',
        label: 'Login',
        exact: true,
        component: lazy(() => import('../auth/login')),
    }, {
        path: '/registration',
        label: 'Register',
        exact: true,
        component: lazy(() => import('../auth/registration')),
    }
];

export const privateRoutes = [
    {
        path: '/',
        label: 'dashboard',
        exact: true,
        component: lazy(() => import('../dashboard/Dashboard')),
    }, {
        path: '/contracts',
        label: 'contracts',
        exact: true,
        component: lazy(() => import('../contracts/Contracts')),
    }, {
        path: '/vehicles/add-vehicle',
        label: 'add-vehicle',
        exact: true,
        component: lazy(() => import('../vehicles/AddVehicle')),
    }, {
        path: '/vehicles/all-vehicles',
        label: 'all-vehicle',
        exact: true,
        component: lazy(() => import('../vehicles/AllVehicles')),
    }, {
        path: '/drivers/all-drivers',
        label: 'all-drivers',
        exact: true,
        component: lazy(() => import('../drivers/AllDrivers')),
    }, {
        path: '/drivers/add-driver',
        label: 'add-driver',
        exact: true,
        component: lazy(() => import('../drivers/AddDriver')),
    }, {
        path: '/orders',
        label: 'orders',
        exact: true,
        component: lazy(() => import('../orders/Orders')),
    },
];

