import React from 'react';
import {
    HomeOutlined,
    CarOutlined,
    UserOutlined,
    BookOutlined,
    OrderedListOutlined
} from '@ant-design/icons';
import {Menu} from 'antd';
import {vehicleMenu, driverMenu, contractsMenu, ordersMenu} from '../utils';
import {Link} from 'react-router-dom';
import User from '../../components/userAvatarToHeader/User';
import {useSelector} from 'react-redux';

const {SubMenu} = Menu;

const Sidebar = ({collapsed}) => {
    const {role} = useSelector(state => state.app);
    return (
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="sub5" icon={<HomeOutlined/>} style={{marginTop: '15px', marginBottom: '15px'}}>
                <Link to="/">Home</Link>
            </Menu.Item>
            <User collapsed={collapsed}/>

            <SubMenu key="sub1" icon={<BookOutlined/>} title={contractsMenu.name}>
                {
                    contractsMenu.paths.map(item => (
                        <Menu.Item key={item.label}>
                            <Link to={item.path}>{item.label}</Link>
                        </Menu.Item>
                    ))
                }
            </SubMenu>
            <SubMenu key="sub2" icon={<CarOutlined/>} title={vehicleMenu.name}>
                {
                    vehicleMenu.paths.map(item => (
                        <Menu.Item key={item.label}>
                            <Link to={item.path}>{item.label}</Link>
                        </Menu.Item>
                    ))
                }
            </SubMenu>
            {
                !role.includes('NaturalPerson') &&
                (
                    <SubMenu key="sub3" icon={<UserOutlined/>} title={driverMenu.name}>
                        {
                            driverMenu.paths.map(item => (
                                <Menu.Item key={item.label}>
                                    <Link to={item.path}>{item.label}</Link>
                                </Menu.Item>
                            ))
                        }
                    </SubMenu>
                )
            }

            <SubMenu key="sub4" icon={<OrderedListOutlined/>} title={ordersMenu.name}>
                {
                    ordersMenu.paths.map(item => (
                        <Menu.Item key={item.label}>
                            <Link to={item.path}>{item.label}</Link>
                        </Menu.Item>
                    ))
                }
            </SubMenu>
        </Menu>
    );
};

export default Sidebar;
