import React, {Suspense, useEffect} from 'react';
import {Switch} from 'react-router-dom';
import {publicRoutes, privateRoutes} from './routes';
import PrivateRoute from './routes/PrivateRoutes';
import PublicRoute from './routes/PublicRoutes';
import {useDispatch, useSelector} from 'react-redux';
import {IS_AUTH, IS_EMAIL_CONFIRM, IS_PHONE_CONFIRM, SET_ROLE} from './redux/actionTypes';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import Confirmation from './confirmation';
import {refreshToken} from './api/refreshToken';


const App = () => {
    const dispatch = useDispatch();
    const {isAuth, email_verified, phone_number_verified} = useSelector(state => state.app);
    const history = useHistory();

    useEffect(() => {
        const auth = sessionStorage.getItem('auth');
        if (auth) {
            const session = JSON.parse(auth);

            dispatch({type: SET_ROLE, payload: session.role});
            dispatch({type: IS_AUTH, payload: true});
            dispatch({type: IS_EMAIL_CONFIRM, payload: session.email_verified});
            dispatch({type: IS_PHONE_CONFIRM, payload: session.phone_number_verified});
            if (history.location.pathname === '/registration') {
                history.push('/');
            }

        } else {
            history.location.pathname !== '/' && history.push('/');
        }
    }, [isAuth]);


    useEffect(() => {
        const auth = sessionStorage.getItem('auth') ?? null;
        if (auth) {
            const session = JSON.parse(auth);
            if (!session.email_verified || !session.phone_number_verified) {
                refreshToken({token: session.token, refreshToken: session.refreshToken});
            }
        }

    }, [email_verified, phone_number_verified]);

    return (
        <Suspense fallback={<h2>loading...</h2>}>
            {
                isAuth
                    ? (
                        <Switch>
                            {
                                email_verified && phone_number_verified
                                    ? privateRoutes.map(route => <PrivateRoute key={route.label} {...route} />)
                                    : <Confirmation/>
                            }
                        </Switch>
                    )
                    : (
                        <Switch>
                            {publicRoutes.map(route => <PublicRoute key={route.label} {...route} />)}
                        </Switch>
                    )
            }
        </Suspense>
    );

};

export default App;

