export const vehicleMenu = {
    name: 'My vehicle',
    paths: [
        {
            label: 'All vehicles',
            path: '/vehicles/all-vehicles'
        },
        {
            label: 'Add vehicle',
            path: '/vehicles/add-vehicle'
        }
    ]
};
