import {
    FETCH_ERROR,
    FETCH_READY,
    FETCH_SUCCESS,
    INIT_DATA_APP,
    IS_AUTH,
    IS_EMAIL_CONFIRM, IS_PHONE_CONFIRM,
    SET_ROLE
} from '../actionTypes';

const initAppState = {
    isAuth: false,
    role: '',
    email_verified: false,
    phone_number_verified: false,
    fetchSuccess: '',
    fetchReady: '',
    errors: [],
    vehicleServices: [],
    bulkCargoVehicles: [],
    trawlingVehicles: [],
    orderStatuses: [],
};

export const app = (state = initAppState, action) => {
    switch (action.type) {
        case IS_AUTH:
            return {
                ...state,
                isAuth: action.payload,
            };
        case SET_ROLE:
            return {
                ...state,
                role: action.payload
            };
        case IS_EMAIL_CONFIRM:
            return {
                ...state,
                email_verified: action.payload,
            };
        case IS_PHONE_CONFIRM:
            return {
                ...state,
                phone_number_verified: action.payload,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                fetchSuccess: action.payload
            };
        case FETCH_READY:
            return {
                ...state,
                fetchReady: action.payload
            };
        case FETCH_ERROR:
            return {
                ...state,
                fetchSuccess: action.payload,
                errors: action.messages
            };
        case INIT_DATA_APP:
            return {
                ...state,
                vehicleServices: action.payload.vehicleServices,
                bulkCargoVehicles: action.payload.bulkCargoVehicles,
                trawlingVehicles: action.payload.trawlingVehicles,
                orderStatuses: action.payload.orderStatuses
            };
        default:
            return state;
    }
};
