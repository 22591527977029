import {ADD_DRIVER, ALL_DRIVERS, GET_DRIVER, UPDATE_DRIVER} from '../actionTypes';

const initAllDrivers = {
    items: []
};

export const allDrivers = (state = initAllDrivers, action) => {
    switch (action.type) {
        case ALL_DRIVERS:
            return {
                ...state,
                items: action.payload
            };
        default:
            return state;
    }
};

export const initDriver = {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    accountNumber: '',
    assignedVehicle: [],
    createdAt: '',
    personalCode: '',
    registrationAddress: '',
    statusType: 2,
    vatCode: '',
    driverVehicles: []
};

export const driver = (state = initDriver, action) => {

    switch (action.type) {
        case ADD_DRIVER:
            return {
                ...state,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                phoneNumber: action.payload.phoneNumber,
                password: action.payload.password,
                driverVehicles: action.payload.driverVehicles
            };
        case GET_DRIVER:
            return {
                ...state,
                id: action.payload.id,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                phoneNumber: action.payload.phoneNumber,
                password: action.payload.password,
                accountNumber: action.payload.accountNumber,
                assignedVehicle: action.payload.assignedVehicle,
                createdAt: action.payload.createdAt,
                personalCode: action.payload.personalCode,
                registrationAddress: action.payload.registrationAddress,
                statusType: action.payload.statusType,
                vatCode: action.payload.vatCode,
                driverVehicles: action.payload.driverVehicles
            };
        case UPDATE_DRIVER:
            return {
                ...state,
                id: action.payload.id,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                phoneNumber: action.payload.phoneNumber,
                password: state.password,
                accountNumber: state.accountNumber,
                assignedVehicle: action.payload.assignedVehicle,
                createdAt: state.createdAt,
                personalCode: state.personalCode,
                registrationAddress: state.registrationAddress,
                statusType: state.statusType,
                vatCode: state.vatCode,
                driverVehicles: action.payload.driverVehicles
            };
        default:
            return state;
    }
};
