import {ORDER_GET_ALL} from '../actionTypes';

const initOrders = {
    items: []
};

export const orders = (state = initOrders, action) => {
    switch (action.type) {
        case ORDER_GET_ALL:
            return {
                ...state,
                items: action.payload
            };
        default:
            return state;
    }
};
