import React from 'react';
import {UserOutlined, LogoutOutlined} from '@ant-design/icons';
import {Avatar, Tooltip} from 'antd';
import './style.less';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {useAction} from '../../hooks/useAction';
import {logoutAction} from '../../redux/thunkCreator/authActions';

const User = ({collapsed}) => {
    const user = JSON.parse(sessionStorage.getItem('auth'));
    const {logoutAction} = useAction();

    const handleLogout = () => {
        logoutAction();
    };

    if (collapsed) {
        return (
            <div className="avatar">
                <div className="avatar__inner">
                    <button className="avatar__logout" onClick={() => handleLogout()}>
                        <Tooltip placement="leftTop" title="logout">
                            <LogoutOutlined style={{fontSize: '18px'}}/>
                        </Tooltip>
                    </button>
                </div>
            </div>
        );
    }
    return (
        <div className="avatar">
            <Avatar className="avatar__img pulse" icon={<UserOutlined style={{fontSize: '32px'}}/>}/>
            <div className="avatar__inner">
                <p className="avatar__name">{user?.user}</p>
                <button className="avatar__logout" onClick={() => handleLogout()}>
                    <Tooltip placement="leftTop" title="logout">
                        <LogoutOutlined style={{fontSize: '18px'}}/>
                    </Tooltip>
                </button>
            </div>

        </div>
    );
};

export default User;
