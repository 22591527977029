import axios from 'axios';
import config from '../config';
import {refreshToken} from './refreshToken';
import {saveToken} from './saveToken';

export function apiCall() {
    const auth = JSON.parse(sessionStorage.getItem('auth')) ?? null;
    if (auth) {
        if (Date.now() >= auth.exp * 1000) {
            try {
                const newToken = refreshToken(auth);
                saveToken(newToken);
                if (newToken) {
                    const auth = JSON.parse(sessionStorage.getItem('auth')) ?? null;
                    return axios.create({
                        baseURL: config.apiUrl,
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth?.token}`,
                        },
                    });
                }

            } catch (e) {
            }
        }
    }

    return axios.create({
        baseURL: config.apiUrl,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth?.token}`,
        },
    });
};
